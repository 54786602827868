import React from "react"
import Word from "../RevealingWord"
const ProjectHeader = () => {
  return (
    <div className="projectHeader">
      <div className="projectHeader-heading">
        <h2 className="pageHeadings text-green">
          <Word
            word={`helping businesses grow by making magnificent web encounters`}
          />
        </h2>
      </div>
    </div>
  )
}

export default ProjectHeader
