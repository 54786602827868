import React from "react"
import Image from "gatsby-image"
import { Link } from "gatsby"
const ProjectCard = props => {
  const brandColor = "#000000"
  return (
    <div className="project-card">
      <div to="/" className="project-card--image">
        <Image
          fluid={props.image}
          loading="eager"
          className="scale-image"
          alt={props.altText}
        />
        <a
          href={props.link}
          style={{ backgroundColor: brandColor }}
          title="Visit Website"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="104.41"
            height="104.766"
            viewBox="0 0 104.41 104.766"
          >
            <g
              id="Group_75"
              data-name="Group 75"
              transform="translate(9571.306 -8462.054)"
            >
              <path
                id="Path_104"
                data-name="Path 104"
                d="M-9533.015,8526l59.757-57.584"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeWidth="9"
              />
              <g id="Group_74" data-name="Group 74">
                <path
                  id="Path_103"
                  data-name="Path 103"
                  d="M-9523.5,8483h-43.306v79.32h79.32v-41.972"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="9"
                />
                <path
                  id="Path_105"
                  data-name="Path 105"
                  d="M-9508.115,8468.416h34.857v33.435"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="9"
                />
              </g>
            </g>
          </svg>
        </a>
      </div>
      <div className="project-card--content">
        <div>
          <h3 className="text-green">{props.name}</h3>
          <p>{props.title}</p>
        </div>
        <Link className="pageLinks" to={`${props.case}`} title="View Case">
          Case Study
        </Link>
      </div>
    </div>
  )
}

export default ProjectCard
