import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/Seo"
import Header from "../components/projectPage/ProjectHeader"
import Layout from "../components/Layout"
import Card from "../components/projectPage/ProjectCard"
const Project = ({ data }) => {
  const projects = data.allPrismicProjects.nodes

  return (
    <Layout>
      <Seo title={`Kubo | Works`} isArticle={false} />
      <Header />
      <div className="projectGrid">
        {projects.map(project => {
          return (
            <Card
              key={project.data.project_title.text}
              image={
                project.data.project_main_image.localFile.childImageSharp.fluid
              }
              altText={project.data.project_main_image.alt}
              title={project.data.project_main_heading.text}
              link={project.data.project_web_link.url}
              name={project.data.project_title.text}
              case={project.uid}
            />
          )
        })}
      </div>
    </Layout>
  )
}

export default Project
export const query = graphql`
  {
    allPrismicProjects(sort: { fields: first_publication_date, order: DESC }) {
      nodes {
        uid
        data {
          project_title {
            text
          }
          project_web_link {
            url
          }
          project_main_heading {
            text
          }
          project_main_image {
            alt
            localFile {
              childImageSharp {
                fluid(maxWidth: 2500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
